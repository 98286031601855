<template>
  <div class="d-inline">
    <b-button variant="primary"
              id="btn-barcode"
              class="ml-2 btn-icon"
              v-b-modal.barcode
              v-c-tooltip.hover="'Barcode Rücklauf scannen'">
      <CIcon name="cil-barcode" class="c-icon-lg"/>
    </b-button>

    <!-- Barcode Modal -->
    <b-modal id="barcode" title="Barcode Rücklauf & Freigabe" header-bg-variant="primary" @show="focusBarcodeInput()" size="lg">
      <p>
        Bitte scanne den Barcode oder aktiviere vorher den Schalter, um ihn freizugeben.
        <br>Die Zuordnung zum ursprünglichen Empfehler erfolgt durch das System automatisch.
        <br>Alternativ kannst du die unter dem Barcode angegebene Nummer manuell eintragen.
      </p>
      <b-form-checkbox v-model="free_barcode" switch>
        Barcode freigeben (keinen Schüler geworben)
      </b-form-checkbox>
      <br>
      <b-form-group>
        <b-form-input type="text"
                      placeholder="Barcode"
                      v-model="barcode"
                      @change="barcode = barcode.replaceAll('ß', '-')"
                      ref="barcode_input">
        </b-form-input>
      </b-form-group>

      <template v-slot:modal-footer="{ ok, cancel, hide }">
        <div class="w-100">
          <b-button variant="outline-danger" class="float-left" @click="cancel()">Abbrechen</b-button>
          <b-button v-if="!free_barcode" variant="primary" class="float-right" @click="returnBarcode()">Barcode Rücklauf</b-button>
          <b-button v-if="free_barcode" variant="primary" class="float-right" @click="releaseBarcode()">Barcode freigeben</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "BarcodeReturn",
  data(){
    return {
      barcode: '',
      free_barcode: false,
    }
  },
  methods: {
    focusBarcodeInput (){
      let self = this;
      setTimeout(function (){
        self.$refs.barcode_input.focus()
      },500);
    },
    returnBarcode () {
      let self = this;
      let massnahme_id = parseInt(self.barcode.split('-')[0]);
      if(massnahme_id === 1){
        Http.request('POST', '/customers/barcodes/return', {barcode: self.barcode}).then(function (response) {
          self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/null/null/data/' + response.data.assigned.id);
        })
        .catch(function (error) {
          self.$bvModal.hide('requestFailureModal');
          if(typeof(error.errors.barcode) !== "undefined"){
            self.$bvToast.toast('Der Barcode ist keinem ihrer Schüler zugewiesen', {
              title: 'Fehler',
              variant: 'danger',
              autoHideDelay: 5000
            });
          }
        });
      }
      else if (massnahme_id > 1) {
        self.$bvModal.msgBoxConfirm('Wurde die Gebühr für die Massnahme dem Schüler erstattet?', {
          headerBgVariant: 'primary',
          title: 'Gebühr erstattet?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if(value === true){
            Http.request('POST', '/customers/barcodes/return', {barcode: self.barcode}).then(function (response) {
              self.barcode = '';
              self.$bvToast.toast('Barcode wurde eingelöst', {
                title: 'Eingelöst',
                variant: 'success',
                autoHideDelay: 5000
              })
            })
            .catch(function (error) {
              self.$bvModal.hide('requestFailureModal');
              if(typeof(error.errors.barcode) !== "undefined"){
                self.$bvToast.toast('Der Barcode ist keinem ihrer Schüler zugewiesen', {
                  title: 'Fehler',
                  variant: 'danger',
                  autoHideDelay: 5000
                });
              }
            });
          }
        });
      }
    },
    releaseBarcode () {
      let self = this;
      Http.request('POST', '/customers/barcodes/release', {barcode: self.barcode}).then(function (response) {
        self.barcode = '';
        self.$bvToast.toast('Der Barcode wurde Freigegeben', {
          title: 'Freigegeben',
          variant: 'success',
          autoHideDelay: 5000
        })
      });
    },
  }
}
</script>

<style scoped>
</style>
